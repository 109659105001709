@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--background: 0 0% 100%;
		--foreground: 222.2 84% 4.9%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;

		--primary: 222.2 47.4% 11.2%;
		--primary-foreground: 210 40% 98%;

		--secondary: 210 40% 96.1%;
		--secondary-foreground: 222.2 47.4% 11.2%;

		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;

		--accent: 210 40% 96.1%;
		--accent-foreground: 222.2 47.4% 11.2%;

		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 210 40% 98%;

		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;
		--ring: 222.2 84% 4.9%;

		--radius: 0.5rem;
	}

	.dark {
		--background: 222.2 84% 4.9%;
		--foreground: 210 40% 98%;

		--card: 222.2 84% 4.9%;
		--card-foreground: 210 40% 98%;

		--popover: 222.2 84% 4.9%;
		--popover-foreground: 210 40% 98%;

		--primary: 210 40% 98%;
		--primary-foreground: 222.2 47.4% 11.2%;

		--secondary: 217.2 32.6% 17.5%;
		--secondary-foreground: 210 40% 98%;

		--muted: 217.2 32.6% 17.5%;
		--muted-foreground: 215 20.2% 65.1%;

		--accent: 217.2 32.6% 17.5%;
		--accent-foreground: 210 40% 98%;

		--destructive: 0 62.8% 30.6%;
		--destructive-foreground: 210 40% 98%;

		--border: 217.2 32.6% 17.5%;
		--input: 217.2 32.6% 17.5%;
		--ring: 212.7 26.8% 83.9%;
	}
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
	}
}

.review-container {
	padding: 1.5em 0;

	margin-bottom: 20vh;
}
.review-bars-wrapper {
	display: none;
}

.review-wrapper {
	margin: auto;
	display: flex;
	overflow-x: scroll;
	width: 100vw;
	gap: 10px;
	-webkit-overflow-scrolling: touch;
	scroll-snap-type: x mandatory;
}

.line-rule {
	border: 0.5px solid var(--light-grey);
	width: 90%;
	margin: 3em auto 2em auto;
}

.review-wrapper::-webkit-scrollbar {
	display: none;
	width: 0px;
}

.review-avatar {
	width: 45px;
	height: 45px;
	border-radius: 50%;
}
.review-avatar img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: inherit;
}
.review-top {
	display: flex;
	align-items: center;
}
.review-details {
	margin-left: 0.5em;
}
.review-name {
	font-weight: 600;
	margin: 0;
	color: var(--text-color-dark);
}

.review-date {
	margin: 0.3em 0 0 0;
	font-size: 14px;
}

.review-section {
	min-width: 83vw;
	border-radius: 0.8em;
	padding: calc(1vmax + 1em);
	scroll-snap-align: center;
	border: 1px solid var(--light-grey);
}

.review-section:first-child {
	margin-left: 5vw;
}

.review-comment {
	line-height: 1.7;
	color: var(--text-color-dark);
}
.review-rating {
	color: var(--text-color-dark);
	font-size: 22px;
	display: flex;
	align-items: center;
	font-weight: 600;
	margin-left: 5vw;
}

.review-cta {
	display: none;
}

.rating-icon {
	font-size: 13x;
	margin-right: 0.3em;
}

@media (min-width: 750px) {
	.review-section:first-of-type {
		margin: 2em;
	}
	.review-section {
		border: none;
		grid-column: span 1;
		padding: 0;
		margin: 2em;
		width: 100%;
	}

	.review-container {
		width: 90%;
		max-width: 1200px;
		margin: auto;
	}

	.review-wrapper {
		display: block;
		width: 100%;
	}
	.review-rating {
		margin-left: 0;
	}
	.line-rule {
		width: 100%;
	}

	.review-bars-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 88%;
		/* margin: 2.5em 0 1.5em 0; */
	}

	.review-section-bars {
		display: flex;
		justify-content: space-between;
		margin: 0.5em 0;
		width: 45%;
		color: var(--text-color-dark);
	}
}

@media (min-width: 1100px) {
	.review-wrapper {
		display: grid;
		width: 100%;
		column-gap: 8%;
		grid-template-columns: 40% 40%;
	}

	.review-section {
		margin: 1.5em 0;
		padding: 0;
		min-width: 0;
	}

	.review-section:first-of-type {
		margin: 1em 0 0 0;
	}

	.review-cta {
		border: none;
		padding: 0.9em;
		display: block;
		border: 1px solid var(--text-color-dark);
		border-radius: 0.5em;
		font-weight: 600;
		background-color: transparent;
	}
}
.rating-show {
	color: var(--text-color-dark);
	font-weight: 600;
	display: inline-flex;
	align-items: center;
}
.rating-icon--show {
	font-size: 12px;
	margin-left: 0.5em;
}

/* :root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );
  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}
@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );
    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );
    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}
body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}
a {
  color: inherit;
  text-decoration: none;
}
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
} */

@font-face {
  font-family: "Silka";
  /* lets us decide how their web fonts will render (or fallback), depending on how long it takes for them to load. */
  font-display: swap;
  src: local("Silka"),
    url("/assets/fonts/silka/silka-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Silka";
  /* Lets us decide how their web fonts will render (or fallback), depending on how long it takes for them to load. */
  font-display: swap;
  src: local("Silka"),
    url("/assets/fonts/silka/silka-medium-webfont.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Silka";
  /* lets us decide how their web fonts will render (or fallback), depending on how long it takes for them to load. */
  font-display: swap;
  /* Local allows you to reference, load, and use locally installed fonts. If the user already has the font installed on their system, this bypasses the network entirely, and is the fastest. */
  src: local("Silka"),
    url("/assets/fonts/silka/silka-bold-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Silka";
  /* lets us decide how their web fonts will render (or fallback), depending on how long it takes for them to load. */
  font-display: swap;
  src: local("Silka"),
    url("/assets/fonts/silka/silka-light-webfont.ttf") format("truetype");
  font-weight: lighter;
  font-style: normal;
}
.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 50px;
}
.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}
.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  height: 5px;
  background-color: #3190ee;
  background-color: #3190ee;
}
.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}
/* //loading animation */
.container-general {
  width: 200px;
  height: 100px;
  padding-top: 100px;
  margin: 0 auto;
}
.ball {
  width: 10px;
  height: 10px;
  margin: 10px auto;
  border-radius: 50px;
}
.ball:nth-child(1) {
  background: #c5d4d9;
  -webkit-animation: right 1s infinite ease-in-out;
  -moz-animation: right 1s infinite ease-in-out;
  animation: right 1s infinite ease-in-out;
}
.ball:nth-child(2) {
  background: #c5d4d9;
  -webkit-animation: left 1.1s infinite ease-in-out;
  -moz-animation: left 1.1s infinite ease-in-out;
  animation: left 1.1s infinite ease-in-out;
}
.ball:nth-child(3) {
  background: #c5d4d9;
  -webkit-animation: right 1.05s infinite ease-in-out;
  -moz-animation: right 1.05s infinite ease-in-out;
  animation: right 1.05s infinite ease-in-out;
}
.ball:nth-child(4) {
  background: #c5d4d9;
  -webkit-animation: left 1.15s infinite ease-in-out;
  -moz-animation: left 1.15s infinite ease-in-out;
  animation: left 1.15s infinite ease-in-out;
}
.ball:nth-child(5) {
  background: #c5d4d9;
  -webkit-animation: right 1.1s infinite ease-in-out;
  -moz-animation: right 1.1s infinite ease-in-out;
  animation: right 1.1s infinite ease-in-out;
}
.ball:nth-child(6) {
  background: #c5d4d9;
  -webkit-animation: left 1.05s infinite ease-in-out;
  -moz-animation: left 1.05s infinite ease-in-out;
  animation: left 1.05s infinite ease-in-out;
}
.ball:nth-child(7) {
  background: #c5d4d9;
  -webkit-animation: right 1s infinite ease-in-out;
  -moz-animation: right 1s infinite ease-in-out;
  animation: right 1s infinite ease-in-out;
}
@-webkit-keyframes right {
  0% {
    -webkit-transform: translate(-15px);
  }
  50% {
    -webkit-transform: translate(15px);
  }
  100% {
    -webkit-transform: translate(-15px);
  }
}
@-webkit-keyframes left {
  0% {
    -webkit-transform: translate(15px);
  }
  50% {
    -webkit-transform: translate(-15px);
  }
  100% {
    -webkit-transform: translate(15px);
  }
}
@-moz-keyframes right {
  0% {
    -moz-transform: translate(-15px);
  }
  50% {
    -moz-transform: translate(15px);
  }
  100% {
    -moz-transform: translate(-15px);
  }
}
@-moz-keyframes left {
  0% {
    -moz-transform: translate(15px);
  }
  50% {
    -moz-transform: translate(-15px);
  }
  100% {
    -moz-transform: translate(15px);
  }
}
@keyframes right {
  0% {
    transform: translate(-15px);
  }
  50% {
    transform: translate(15px);
  }
  100% {
    transform: translate(-15px);
  }
}
@keyframes left {
  0% {
    transform: translate(15px);
  }
  50% {
    transform: translate(-15px);
  }
  100% {
    transform: translate(15px);
  }
}
@media (min-width: 500px) {
  .mk-block {
    display: block;
  }
  .make-85 {
    width: 85%;
  }
}
.name {
  height: 90vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto;
  padding: 0 1rem 1rem;
  position: relative;
}
.name:before {
  content: "";
  width: 100%;
  /* background: #3190ee; */
  opacity: 0.3;
  bottom: 0;
  height: 1px;
  left: 0;
  position: absolute;
}
.name .letter {
  font-size: 48px;
  display: inline-block;
  opacity: 0;
  transform: scale(0.9);
  font-weight: 600;
  color: #3190ee;
}


/*********** Baseline, reset styles ***********/
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 25rem;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

/******** Chrome, Safari, Opera and Edge Chromium styles ********/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: #ededed;
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -20px; /* Centers thumb on the track */
  background-color: #0066ff;
  border-radius: 2rem;
  height: 3rem;
  width: 3rem;
  border: 3px solid #06439f; /*Removes extra border that FF applies*/
}

input[type="range"]:focus::-webkit-slider-thumb {
  outline: 3px solid #0066ff;
  outline-offset: 0.125rem;
}

/*********** Firefox styles ***********/
/* slider track */
input[type="range"]::-moz-range-track {
  background-color: #ededed;
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  background-color: #0066ff;
  border: 18px solid #06afa6; /*Removes extra border that FF applies*/
  border-radius: 2rem;
  height: 3rem;
  width: 3rem;
}

input[type="range"]:focus::-moz-range-thumb{
  outline: 3px solid #0066ff;
  outline-offset: 0.125rem;
}